import PropTypes from "prop-types";
import React, { Component } from "react";
//import FacebookLogin from 'react-facebook-login';
//import GoogleLogin from 'react-google-login';
import CONSTANTS from "../../constants";
import { authenticationService } from '../../services/Authentication.js'
import { Modal } from "react-bootstrap"
//import { useGoogleLogout } from 'react-google-login'
import { NavDropdown } from "react-bootstrap";
import { accountService } from '../../services/accountService.js';

export default class LoginButton extends Component {

  static propTypes = {
    user: PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.string,
      email: PropTypes.string,
      userid: PropTypes.string,
      Streak: PropTypes.string,
     id: PropTypes.string,
     accessToken: PropTypes.string,
     userID: PropTypes.string,
     expiresIn: PropTypes.string,
     signedRequest: PropTypes.string,
     data_access_expiration_time: PropTypes.string
    })
  };

  constructor(props, context) {
    super(props);
    this.logout = this.logout.bind(this);
    var cu = authenticationService.currentUserValue;
    this.showMenu = this.showMenu.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.loginCustom = this.loginCustom.bind(this);
    this.registerCustom = this.registerCustom.bind(this);
    this.loginFacebook = this.loginFacebook.bind(this);
    //this.responseFacebook = this.responseFacebook.bind(this);
   





    if(cu)
    {
      this.state = {
        user: cu,
        authenticated: true,
        showMenu: false,
        showModal: false,
      }
    }
    else
    {
      this.state = {
        user: null,
        authenticated: false,
        showMenu: false,
        showModal: false,
      }
    }
  

    };


    showMenu(event) {
      event.preventDefault();
      if(this.state.showMenu)
      {

        this.setState({
          showMenu: false,
        });
        
      }
      else{

      this.setState({
        showMenu: true,
      });
    }
    }


    handleChange(e) {
      this.setState({ [e.target.name]: e.target.value });
    }
  
  
    addUserToDB(response){
      const self = this;
console.log("addding shit")
  fetch(CONSTANTS.ENDPOINT.LOGIN, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(response)
        
      })
      .then(things =>  {
        console.log("things here")
          return things.json();
      }).then(that => {

        console.log("Add user to state");
            this.setState({
              authenticated: true,
              user: that,
              userImage: response.image
            })
        console.log("User added");
            authenticationService.login(that);
          this.handleClose();
    })
    .catch(error =>
      {
      console.log("Line 119 Error " + error)
      self.setState({
        WarningMessageOpen: true,
        WarningMessageText: `${CONSTANTS.ERROR_MESSAGE.LIST_ADD} ${error}`
      })
      }
      );

  }

  componentWillMount(){
    document.addEventListener('mousedown', this.handleClick);
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
}
handleClick = (e) => {
  if(this.node.contains(e.target))
  {
    return
  }
  this.setState({
    showMenu: false,
  });
}


  loginCustom()
  {
    if(!this.state.loginEmail  || !this.state.loginPassword )
    { 
      this.setState({
        loginError: "Please fill out both fields"
      })   
      return;
    }
    fetch("/api/LoginWithEmail", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: this.state.loginEmail,
        password: this.state.loginPassword
      })
    })
    .then(things =>  {
        return things.json();
    }).then(that => {
      console.log(that)
      if(that)
      if(that.data.email){
        this.setState({
          loginError: ""
        })
        console.log("LoginWithEmail that");
        console.log(that);

          this.setState({
            authenticated: true,
            user: that.data,
            userImage: that.data.image
          })

          authenticationService.login(that.data);
        this.handleClose();
      }
      else
      {
        this.setState({
        loginError: that.message.toString()
      })
    }
  })
  .catch(error => {
    console.log("Line 192 Error " + error)
    this.setState({
      WarningMessageOpen: true,
      WarningMessageText: `${CONSTANTS.ERROR_MESSAGE.LIST_ADD} ${error}`
    })
    
    }
    );

  }


  async loginFacebook()
  {
    console.log("Pre - accountService.login")
    var accnt =  await accountService.login();
    console.log("Post - accountService.login - accnt()")
    console.log(accnt)
    if(!accnt)
    {
      console.log("No Account")
    }
    //console.log(accnt.name)
    try{
    fetch("/api/Login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: accnt.id,
        facebookId: accnt.facebookId,
        name: accnt.name,
        email: accnt.email,
        picture: accnt.picture,
        source: "Facebook"
      })
    })
    .then(things =>  {
        return things.json();
    }).then(that => {
      console.log("that1");
   
      console.log(that);
      console.log(that.data);
      console.log(that.status);
      console.log("that2");
      

      if(that.status === 1)
        {
            if(that.data.email){
              this.setState({
                loginError: ""
              })
              console.log("that2");  
              console.log(that.data);
                
                this.setState({
                  authenticated: true,
                  user: that.data,
                  userImage: that.data.image
                })

                authenticationService.login(that.data);
              this.handleClose();
            }
            else
            {
              console.log("There was no email address")
              this.setState({
              loginError: that.message.toString()
            })
          }
      }
      else(
        this.setState({
          loginError: that.message.toString()
        })
      )
  })
  }
  catch(error) 
    {
      console.log("FB LOGIN ERROR")
      console.log(error);
      this.setState({
      WarningMessageOpen: true,
      WarningMessageText: error
    })
    }
  

/*
    fetch("/api/LoginWithEmail", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: this.state.loginEmail,
        password: this.state.loginPassword
      })
    })
    .then(things =>  {
        return things.json();
    }).then(that => {
      console.log(that)
      if(that)
      if(that.email){
        this.setState({
          loginError: ""
        })
          console.log(that);
          console.log("that");
          this.setState({
            authenticated: true,
            user: that,
            userImage: that.image
          })

          authenticationService.login(that);
        this.handleClose();
      }
      else
      {
        this.setState({
        loginError: that.message.toString()
      })
    }
  })
  .catch(error =>
    this.setState({
      WarningMessageOpen: true,
      WarningMessageText: `${CONSTANTS.ERROR_MESSAGE.LIST_ADD} ${error}`
    }));
*/
  }



  registerCustom()
  {
    var valid = true;
    console.log(this.state.regEmail);
    if(!this.state.regEmail || !this.state.regPassword || !this.state.regName)
    {
      valid = false;   
      this.setState({
        registerError: "Please fill out all fields"
      })   
      return;
    }
    if(this.state.regPassword !== this.state.regConfirmPassword)
    {
      valid = false;   
      this.setState({
        registerError: "Passwords do not match"
      })   
      return;
    }
    if(!RegExp(/^[a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]/).test(this.state.regEmail))
    {
      console.log(RegExp(/^[a-zA-Z0-9]+@+[a-zA-Z0-9]+.+[A-z]/).test(this.state.regEmail))
      valid = false;
      this.setState({
        registerError: "Please enter a valid email address"
      })
      return;
    }

    if(this.state.regPassword.length < 6)
    {
      valid = false;
      this.setState({
        registerError: "Password must be at least 6 characters"
      })
      return;

    }
if(valid)
{
  this.setState({
    registerError: ""
  })
console.log("Registering");
    fetch("/api/Register", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name:this.state.regName,
        email: this.state.regEmail,
        password: this.state.regPassword,
        source: "Email"
      })
    })
    .then(things =>  {
      console.log("got things Register")
      console.log(things)
        return things.json();
    }).then(that => {
      console.log("that");
      console.log(that);
      if(that.message !== "User Already Exists")
      {
        
        if(that.data.email)
        {
          console.log(that.data.email)
      this.setState({
        error: "Your account has been successfully created",
        authenticated: true,
        user: that.data,
        userImage: that.data.image,
        WarningMessageOpen: true,
        showModal: false,
        WarningMessageText: "Your account has been successfully created"
      })

      authenticationService.login(that.data);
    }
    else
    {
      this.setState({
        error: "There was a login issue",
        WarningMessageOpen: true,
        WarningMessageText: "There was a login issue"
      })

    }
      }
      else{
        this.setState({
          error: "A user with that email address already exists. Please login",
          WarningMessageOpen: true,
          WarningMessageText: "A user with that email address already exists. Please login"
        })
      }
  })
  .catch(error =>
    {
      console.log("Error!");
      console.log(error);
    this.setState({
      WarningMessageOpen: true,
      WarningMessageText: `${CONSTANTS.ERROR_MESSAGE.LIST_ADD} ${error}`
    });
  }
    );
}
  }

  responseGoogleFail = (response) => {
  }

  responseGoogle = (response) => {
    console.log("Google res: About to login to DB");
    if (response.accessToken != null) {
      console.log("user found");
      let user= {
        name: response.profileObj.name,
        email: response.profileObj.email,
        source: "Google",
        id: response.profileObj.googleId,
        image: response.profileObj.imageUrl
      };

      console.log(user);
      this.addUserToDB(user);
    }
    console.log("Google Response done");
    this.setState({showModal: false});
  }

  handleClose = () => this.setState({showModal: false});

  
  checkLoginState() {
    window.FB.getLoginStatus = (response) => {
       this.responseFacebook(response);
    };
  }

  handleShow ()
  {
    this.setState({showModal: true});
  }
  
  

  logout() {
    console.log("Logout Button Clicked");
    
    /// The below is for Google Logout. Not yet sure how to implement
    try{
      /*
    if(useGoogleLogout)
    {
      useGoogleLogout.signOut().then(function () {
        console.log('Google user signed out.');
      });
    }*/
    console.log(this.state.user.source)
    if(this.state.user.source === "Facebook")
    {
      console.log("accessToken")
      console.log(this.state.user.accessToken)
      console.log("FB Logout should be started")
      window.FB.logout();
      console.log("FB Logout should be complete")
    }
    authenticationService.logout();
    console.log("AuthserviceCalled")
    this.setState({
      authenticated: false,
      user: {}
    })
    console.log("Trivologist logged out");
  }
  catch(error)
  {
    console.log("Logout failed");
    console.log(error);
  }
  finally{
   
  }
}

componentDidMount(){
  console.log("Starting");
/*
  window.fbAsyncInit = function() {
    window.FB.init({
      appId      : '551480812354664',
      cookie     : true,
      xfbml      : true,
      version    : 'v15.0'
    });
    
    console.log("Starting33")
      
    window.FB.AppEvents.logPageView();   
    console.log("Starting2")
      
  };*/
/*
  window.FB.getLoginStatus = (response) => {
    console.log("Starting333");

    this.responseFacebook(response);
    console.log("Startingasdasd");

  }
*/
  (function(d, s, id){
     var js, fjs = d.getElementsByTagName(s)[0];
     if (d.getElementById(id)) {return;}
     js = d.createElement(s); js.id = id;
     js.src = "https://connect.facebook.net/en_US/sdk.js";
     fjs.parentNode.insertBefore(js, fjs);
    console.log("Startin3g")
    console.log(d)
    console.log(s)
    console.log(id)

   }(document, 'script', 'facebook-jssdk'));


}

/*
responseFacebook = (response) => {
  console.log("FB res: About to login to DB");
  if (response.accessToken != null) {
    console.log("user found");
    response.source = "Facebook"
    console.log(response)
    let user= {
      name: response.name,
      email: response.email,
      id: response.id,
      source: "Facebook",
      image: "http://graph.facebook.com/"+response.id+"/picture?type=small",
      accessToken: response.accessToken
    };
    this.addUserToDB(user);
  }
  console.log("FB Response done");
  this.setState({showModal: false});
}
*/

  render() {
    const { isAdmin, isContributor } = this.state;
    const { authenticated } = this.state;
    return (
      <div ref={node =>this.node = node} className="loginContainer">
          {!authenticated ? (
            <div>
              <button className="button loginButton" onClick={this.handleShow}>Login</button>
              </div>
          ) : (
            <div className="loggedInUser">
              
              <span className="d-none d-sm-inline">Welcome {this.state.user.name}</span>
              {this.state.user.image !== undefined &&  this.state.user.image !== null ?(
          
              <img onClick={this.showMenu} className="profile" src={this.state.user.image} alt="Profile Pic" />
              ):
              (
                <img onClick={this.showMenu} className="profile" src="/Media/BasicProfileImage.png" alt="Profile Pic" />
                
              )  
            }
              {
          this.state.showMenu
            ? (
              <div className="profMenu">
         <NavDropdown.Item href="/Profile">Profile</NavDropdown.Item>
         {(isAdmin || isContributor) && <NavDropdown.Item  to="/List" className="nav-item navTop nav-link">List</NavDropdown.Item >}
{(isAdmin || isContributor)  && <NavDropdown.Item  to="/Admin" className="nav-item navTop nav-link">Admin</NavDropdown.Item >}

                <NavDropdown.Item onClick={this.logout.bind(this)}>Logout</NavDropdown.Item>
              </div>
             ): (
              null
            )
            }
            <br /><span>Current Streak: {this.state.user.Streak}</span>
            </div>
          )}

<Modal show={this.state.showModal} onHide={this.handleClose}>
  <Modal.Header closeButton>
    <Modal.Title>Login or Register</Modal.Title>
  </Modal.Header>

  <Modal.Body>
  <div className="modal-body text-center row">
    <div className="col-12">
          <span className="error">{this.state.loginError}</span>
          </div>
          <div className="col-6" >
          Login with Social Media:
 {/*
 <div className="fb-login-button" data-width="" data-size="small" data-button-type="login_with" data-layout="default" data-auto-logout-link="true" data-use-continue-as="true"></div>
 

 <fb:login-button 
  scope="public_profile,email"
  onlogin="checkLoginState();">
</fb:login-button>
ffh
/*
          */}

{/*Removing this for now to get Android App Login working
                    <button className="fb-login-button" onClick={this.loginFacebook}>
                        <i className="fa fa-facebook mr-1"></i>
                        Login with Facebook
                    </button>
                    */}
                    

{/*}
          <GoogleLogin
    clientId="194586674821-ko85ebk6b1c4uoh05h5cfe64kq84k80f.apps.googleusercontent.com"
    buttonText="Login"
    className="ggButton"
    cssClass="ggButton"
    onSuccess={this.responseGoogle}
    onFailure={this.responseGoogleFail}
    cookiePolicy={'single_host_origin'}
    isSignedIn={false}>
    Login with Google
    </GoogleLogin>*/}
           </div>
        <div className="col-6" >
        Login with Existing Account:
        <input className="textField"  onChange={this.handleChange} name="loginEmail" type="text" placeholder="Email Address" />
         <input onChange={this.handleChange}   className="textField" name="loginPassword"  type="password" placeholder="Password" />
         <button  className="button buttonForm" onClick={this.loginCustom}>Login</button>
         </div>
         <div className="row">
         <div className="col-12">
         
         <br />OR<br />
              Register a new account:
              <span className="error">{this.state.registerError}</span>
              </div>
              
              <div className="col-6">
                <input onChange={this.handleChange}  className="textField" tabIndex="1" name="regName" type="text" placeholder="Name" />
                <input onChange={this.handleChange}   className="textField" tabIndex="3" name="regPassword"  type="password" placeholder="Password" />
                </div> 
                <div className="col-6">
                <input onChange={this.handleChange}  className="textField" tabIndex="2" name="regEmail" type="text" placeholder="Email Address" />
                <input onChange={this.handleChange}   className="textField" tabIndex="4" name="regConfirmPassword"  type="password" placeholder="Confirm Password" />
                </div>
                <div className="col-12">
         <button className="button buttonForm" onClick={this.registerCustom}>Register</button>
         
            </div>
            </div>
           
           
      </div>
  </Modal.Body>
</Modal>

      </div>
    );
  }


}
